<template>
    <div :class="{'blur-content': this.isModalActive}"
         class="row g-5">
        <!-- 1 - PROJETS EN COURS -->
        <div class="col-12">
            <div class="card card-stretch">
                <!--begin::Header-->
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder text-dark fs-3">Projets en cours</span>
                    </h3>
                    <div class="card-toolbar">
                        <ul class="nav nav-pills nav-pills-sm nav-light">
                            <li class="nav-item">
                                <a class="nav-link btn btn-primary fw-bolder me-2" @click="modalCreateProjectShow()">Nouveau projet</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-3 mt-n3">
                    <div class="tab-content mt-4" id="myTabTables2">
                        <!--begin::Table-->
                        <div class="table-responsive">
                            <table class="table table-borderless align-middle">
                                <thead>
                                <tr>
                                    <th class="px-2 py-0 w-50px"></th>
                                    <th class="px-2 py-0 w-100"></th>
                                    <th class="px-2 py-0 min-w-200px"></th>
                                    <th class="px-2 py-0 min-w-100px"></th>
                                    <th class="px-2 py-0 min-w-50px"></th>
                                    <th class="px-2 py-0 min-w-100px"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="project in this.sortedProjects.filter(p => !p.archived)" :key="project.id" @click="this.navigateToProject(project.id)" class="bg-hover-light">
                                    <td class="px-0 py-3 rounded-start p-5">
                                        <div class="symbol symbol-55px ms-5 mt-1 me-5">
                        <span class="symbol-label bg-light-primary align-middle align-items-end">
                <!--         REMPLACER PAR IMAGE-AVATAR DU PROJET <img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg" class="mh-40px" />-->
                          <i class="bi bi-bricks fs-1 align-middle align-self-center"></i>
                        </span>
                                        </div>
                                    </td>
                                    <td class="px-0">
                                        <p class="text-gray-800 fw-bolder fs-6 my-1">{{ project.name }}</p>
                                        <span class="text-muted fw-bold d-block mt-1">{{ project.address}}</span>
                                    </td>
                                    <td class="px-0 text-center">
                                        <div v-if="project?.currentPhase"><span class="bg-light text-gray-800 border text-center rounded py-1 px-2 h-30px">{{ project.currentPhase }}</span></div>
                                    </td>
                                    <td class="px-0 text-center">
                                        <!--      <div v-if="project?.team?.length" class="d-grid justify-content-center m-auto" style="grid-template-columns: repeat(auto-fit,  minmax(10px, max-content))">
                                              <div class="bg-light text-gray-800 border w-auto text-center rounded rounded-circle text-center align-content-center w-25px h-25px" style="z-index: 3;">A</div>
                                              <div class="bg-light text-gray-800 border w-auto text-center rounded rounded-circle text-center align-content-center w-25px h-25px" style="z-index: 2; transform: translateX(-7px);">B</div>
                                              <div class="bg-light text-gray-800 border w-auto text-center rounded rounded-circle text-center align-content-center w-25px h-25px" style="z-index: 1; transform: translateX(-14px);">C</div>
                                            </div>-->
                                    </td>
                                    <td></td>
                                    <td class="px-0 rounded-end">
                                        <button v-tooltip:bottom="'Archiver ce projet'" @click="this.modalConfirmArchiveProjectShow(project.id)" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"><i class="bi bi-archive align-baseline text-center"></i></button>
                                        <button v-tooltip:bottom="'Dupliquer ce projet'" @click="this.modalDuplicateFromProjectShow(project.id)" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"><i class="fa fa-copy align-baseline text-center"></i></button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!--end::Table-->
                </div>
                <!--end::Body-->
            </div>
        </div>
        <!-- 3 - PROJETS ARCHIVÉS -->
        <div class="col-12">
            <!--begin::Table Widget 2-->
            <div class="card card-stretch">
                <!--begin::Header-->
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder text-dark fs-3">Projets archivés</span>
                    </h3>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-3 mt-n3">
                    <div class="tab-content mt-4" id="myTabTables2">
                        <!--begin::Table-->
                        <div class="table-responsive">
                            <table class="table table-borderless align-middle">
                                <thead>
                                <tr>
                                    <th class="p-0 w-50px"></th>
                                    <th class="p-0 min-w-150px"></th>
                                    <th class="px-2 py-0 w-100px"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="project in this.sortedProjects.filter(p => p.archived)" :key="project.id" @click="this.navigateToProject(project.id)" class="bg-hover-light">
                                    <td class="px-0 py-3 rounded-start p-5">
                                        <div class="symbol symbol-55px ms-5 mt-1 me-5">
                        <span class="symbol-label bg-light-primary align-middle align-items-end">
                <!--         REMPLACER PAR IMAGE-AVATAR DU PROJET <img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg" class="mh-40px" />-->
                            <i class="bi bi-bricks fs-1 align-middle align-self-center"></i>
                        </span>
                                        </div>
                                    </td>
                                    <td class="px-0">
                                        <p class="text-gray-800 fw-bolder fs-6 my-1">{{ project.name }}</p>
                                        <span class="text-muted fw-bold d-block mt-1">{{ project.address}}</span>
                                    </td>
                                    <td class="p-0 rounded-end">
                                        <button v-tooltip:bottom="'Désarchiver ce projet'" @click="this.modalConfirmUnarchiveProjectShow(project.id)" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"><i class="bi bi-life-preserver align-baseline text-center"></i></button>
                                        <button disabled v-tooltip:bottom="'Supprimer ce projet'" @click="this.modalConfirmDeleteProjectShow(project.id)" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-danger btn-sm btn-active-white me-2"><i class="bi bi-trash align-baseline text-center"></i></button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!--end::Table-->
                </div>
                <!--end::Body-->
            </div>
            <!--end::Table Widget 2-->
        </div>
    </div>
    <!-- MODAL CREATE NEW PROJECT -->
    <div class="modal"
         tabindex="-1"
         :style="this.modals.createProject.show ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="modalCreateProjectCancel()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <div ref="contactEditModalForm"
                                     class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Nouveau projet</h3>
                                            </div>
                                            <!--end::Heading-->

                                            <!--begin::Form Group-->
                                            <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                <label class="fs-6 fw-bolder text-dark form-label">Nom du projet</label>
                                                <input v-model="modals.createProject.newProject.name" type="text" class="form-control form-control-lg form-control-solid" placeholder="P03 - TERRE-NEUVE">
                                            </div>
                                            <!--end::Form Group-->

                                            <!--begin::Form Group-->
                                            <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                <label class="fs-6 fw-bolder text-dark form-label">Opération</label>
                                                <input v-model="modals.createProject.newProject.newInfoOpName" type="text" class="form-control form-control-lg form-control-solid" placeholder="Surélévation d'un atelier">
                                            </div>
                                            <!--end::Form Group-->

                                            <!--begin::Form Group-->
                                            <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                <label class="fs-6 fw-bolder text-dark form-label">Adresse</label>
                                                <input v-model="modals.createProject.newProject.newInfoOpAddress" type="text" class="form-control form-control-lg form-control-solid" placeholder="26 rue Terre-Neuve, 1005 Bruxelles">
                                            </div>
                                            <!--end::Form Group-->
                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button class="btn btn-lg btn-light"
                                                    @click="modalCreateProjectCancel()">
                                                Annuler
                                            </button>
                                            <button class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                                                    :disabled="!modals.createProject.newProject.name"
                                                    @click="modalCreateProjectValidate()">
                                                Ajouter le projet
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </div>
                                <!--end::Form-->
                            </div>
                            <!--end::Content-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL DUPLICATE PROJECT -->
    <div class="modal"
         tabindex="-1"
         :style="this.modals.duplicateProject.show ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="modalDuplicateFromProjectCancel()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <div ref="contactEditModalForm"
                                     class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Nouveau projet</h3>
                                                <p>Ce projet va copier le descriptif, les lots et les emplacements du projet
                                                    {{ this.$store.getters.getProjectById(this.modals.duplicateProject.oldProjectId)?.name }}</p>
                                            </div>
                                            <!--end::Heading-->

                                            <!--begin::Form Group-->
                                            <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                <label class="fs-6 fw-bolder text-dark form-label">Nom du projet</label>
                                                <input v-model="modals.duplicateProject.newProject.name" type="text" class="form-control form-control-lg form-control-solid" placeholder="P03 - TERRE-NEUVE">
                                            </div>
                                            <!--end::Form Group-->

                                            <!--begin::Form Group-->
                                            <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                <label class="fs-6 fw-bolder text-dark form-label">Opération</label>
                                                <input v-model="modals.duplicateProject.newProject.newInfoOpName" type="text" class="form-control form-control-lg form-control-solid" placeholder="Surélévation d'un atelier">
                                            </div>
                                            <!--end::Form Group-->

                                            <!--begin::Form Group-->
                                            <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                <label class="fs-6 fw-bolder text-dark form-label">Adresse</label>
                                                <input v-model="modals.duplicateProject.newProject.newInfoOpAddress" type="text" class="form-control form-control-lg form-control-solid" placeholder="26 rue Terre-Neuve, 1005 Bruxelles">
                                            </div>
                                            <!--end::Form Group-->

                                            <!--begin::Form Group-->
                                            <div class="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                <div class="align-center form-check mt-6 form-switch">
                                                    <label class="fs-6 text-dark form-label" for="duplicateLocations">Dupliquer les emplacements</label>
                                                    <input v-model="this.modals.duplicateProject.duplicateLocations" id="duplicateLocations" type="checkbox" class="form-check-input">
                                                </div>
                                            </div>

                                            <!--begin::Form Group-->
                                            <div class="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                <div class="align-center form-check mt-3 form-switch">
                                                    <label class="fs-6 text-dark form-label" for="duplicateContacts">Dupliquer les entreprises et intervenants</label>
                                                    <input v-model="this.modals.duplicateProject.duplicateContacts" id="duplicateContacts" type="checkbox" class="form-check-input">
                                                </div>
                                            </div>

                                            <!--begin::Form Group-->
                                            <div class="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                <div class="align-center form-check mt-3 form-switch">
                                                    <label class="fs-6 text-dark form-label" for="duplicateQts">Conserver les quantitatifs</label>
                                                    <input v-model="this.modals.duplicateProject.duplicateQts" id="duplicateQts" type="checkbox" class="form-check-input">
                                                </div>
                                            </div>
                                            <!--end::Form Group-->
                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button class="btn btn-lg btn-light"
                                                    @click="modalDuplicateFromProjectCancel()">
                                                Annuler
                                            </button>
                                            <button class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                                                    :disabled="!modals.duplicateProject.newProject.name"
                                                    @click="modalDuplicateFromProjectValidate()">
                                                Ajouter le projet
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </div>
                                <!--end::Form-->
                            </div>
                            <!--end::Content-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL CONFIRM ARCHIVE PROJECT -->
    <div class="modal"
         tabindex="-1"
         :style="this.modals.archiveProject.show ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalConfirmArchiveProjectCancel()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content0-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <form ref="contactModalForm"
                                      class="pb-5 w-100 w-sm-400px" novalidate>
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Archiver le projet</h3>
                                            </div>
                                            <p>
                                                Vous pourrez toujours le consulter, le dupliquer, mais plus le modifier ou en faire des exports. Si besoin de le désarchiver, n'hésitez pas à contacter le service client :)
                                            </p>
                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button type="button" @click="this.modalConfirmArchiveProjectCancel()" class="btn btn-lg btn-light">
                                                Annuler
                                            </button>
                                            <button type="button" @click="this.modalConfirmArchiveProjectValidate()" class="btn btn-lg btn-primary ms-2">
                                                Confirmer
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </form>
                                <!--end::Form-->
                            </div>
                            <!--end::Content0-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL CONFIRM UNARCHIVE PROJECT -->
    <div class="modal"
         tabindex="-1"
         :style="this.modals.unarchiveProject.show ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalConfirmUnarchiveProjectCancel()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content0-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <form ref="contactModalForm"
                                      class="pb-5 w-100 w-sm-400px" novalidate>
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Désarchiver le projet</h3>
                                            </div>
                                            <p>
                                                Welcome back :)
                                            </p>
                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button type="button" @click="this.modalConfirmUnarchiveProjectCancel()" class="btn btn-lg btn-light">
                                                Annuler
                                            </button>
                                            <button type="button" @click="this.modalConfirmUnarchiveProjectValidate()" class="btn btn-lg btn-primary ms-2">
                                                Confirmer
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </form>
                                <!--end::Form-->
                            </div>
                            <!--end::Content0-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL CONFIRM DELETE PROJECT -->
    <div class="modal"
         tabindex="-1"
         :style="this.modals.deleteProject.show ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-danger" @click="this.modalConfirmDeleteProjectCancel()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content0-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <form ref="contactModalForm"
                                      class="pb-5 w-100 w-sm-400px" novalidate>
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Confirmer la suppression</h3>
                                            </div>
                                            <p>
                                                Attention, c'est définitif et sans annulation possible !
                                            </p>
                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button type="button" @click="this.modalConfirmDeleteProjectCancel()" class="btn btn-lg btn-light">
                                                Annuler
                                            </button>
                                            <button type="button" @click="this.modalConfirmDeleteProjectValidate()" class="btn btn-lg btn-danger ms-2">
                                                Confirmer
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </form>
                                <!--end::Form-->
                            </div>
                            <!--end::Content0-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {
    ALL_PROJECTS_QUERY, MUTATION_CREATE_PROJECT,
    MUTATION_DUPLICATE_PROJECT
} from "@/graphql/graphql";
import kalmStore from "@/store";


export default {
    name: "ProjectsPage",
    apollo: {
        projects: {
            query: ALL_PROJECTS_QUERY,
        },
    },
    components: { },
    methods: {
        modalConfirmArchiveProjectShow(projectId) {
            event.stopPropagation()
            this.modals.archiveProject.projectId = projectId
            this.modals.archiveProject.show = true;
        },
        modalConfirmArchiveProjectCancel() {
            this.modals.archiveProject.projectId = "";
            this.modals.archiveProject.show = false;
        },
        modalConfirmArchiveProjectValidate() {
            const apolloClient = this.$apollo;
            const projectId = this.modals.archiveProject.projectId;
            kalmStore.commit("archiveProjectMutation", {projectId, apolloClient})
            this.modals.archiveProject.show = false;
        },
        modalConfirmUnarchiveProjectShow(projectId) {
            event.stopPropagation()
            this.modals.unarchiveProject.projectId = projectId
            this.modals.unarchiveProject.show = true;

        },
        modalConfirmUnarchiveProjectCancel() {
            this.modals.unarchiveProject.projectId = "";
            this.modals.unarchiveProject.show = false;
        },
        modalConfirmUnarchiveProjectValidate() {
            const apolloClient = this.$apollo;
            const projectId = this.modals.unarchiveProject.projectId;
            kalmStore.commit("unarchiveProjectMutation", {projectId, apolloClient})
            this.modals.unarchiveProject.show = false;
        },
        modalConfirmDeleteProjectShow(projectId) {
            event.stopPropagation()
            this.modals.deleteProject.projectId = projectId;
            this.modals.deleteProject.show = true;
        },
        modalConfirmDeleteProjectCancel() {
            this.modals.deleteProject.show = false;
        },
        modalConfirmDeleteProjectValidate() {
            const apolloClient = this.$apollo;
            kalmStore.commit("deleteProjectMutation", {projectId: this.modals.deleteProject.projectId, apolloClient})
            this.modals.deleteProject.show = false;
        },
        modalDuplicateFromProjectShow(projectId) {
            this.modals.duplicateProject.oldProjectId = projectId;
            const oldProject = kalmStore.getters.getProjectById(projectId);
            this.modals.duplicateProject.newProject.name = "Copie de "+oldProject.name;
            this.modals.duplicateProject.newProject.newInfoOpName = oldProject.infoOpName;
            this.modals.duplicateProject.newProject.newInfoOpAddress = oldProject.address;
            this.modals.duplicateProject.duplicateLocations = true;
            this.modals.duplicateProject.duplicateContacts = true;
            this.modals.duplicateProject.duplicateQts = true;

            this.modals.duplicateProject.show = true;
            event.stopPropagation();
        },
        modalDuplicateFromProjectCancel() {
            this.modals.duplicateProject.show = false;
        },
        modalDuplicateFromProjectValidate() {

            const oldProjectId = this.modals.duplicateProject.oldProjectId;
            const newProjectName = this.modals.duplicateProject.newProject.name;
            const newInfoOpName = this.modals.duplicateProject.newProject.newInfoOpName;
            const newInfoOpAddress = this.modals.duplicateProject.newProject.newInfoOpAddress;
            const duplicateLocations = this.modals.duplicateProject.duplicateLocations;
            const duplicateContacts = this.modals.duplicateProject.duplicateContacts;
            const duplicateQts = this.modals.duplicateProject.duplicateQts;


            this.$apollo.mutate(
                {
                    mutation: MUTATION_DUPLICATE_PROJECT,
                    variables: {
                        oldProjectId,
                        newProjectName,
                        newInfoOpName,
                        newInfoOpAddress,
                        duplicateLocations,
                        duplicateContacts,
                        duplicateQts,
                    },
                    update: (store, { data: { projectDuplicate }  }) => {
                        // Read the data from our cache for this query.
                        let data = store.readQuery({query: ALL_PROJECTS_QUERY})

                        const p = projectDuplicate.project

                        // Add new project from the mutation to cache
                        data = {
                            ...data,
                            projects: [ ...data.projects, p ],
                        }

                        // Write our data back to the cache.
                        store.writeQuery({query: ALL_PROJECTS_QUERY, data})

                        kalmStore.commit("initializeLocalProject", {
                            projectId: p.id,
                            lots: p.lots,
                            locations: p.locations,
                            contacts: p.contacts,
                            name: p.name,
                            address: p.address,
                            infoOpName: p.infoOpName,
                            importantInfo: p.importantInfo,
                            archived: p.archived,
                            newProject: JSON.parse(p.document),
                            featureMultiLocation: p.featureMultiLocation,
                            currentPhase: p.currentPhase,
                            phases: JSON.parse(p.phases) || [],
                        })
                    },
                }
            )


            this.modals.duplicateProject.show = false;
        },
        navigateToProject(projectId) {
            this.$router.push({
                name: "project.home",
                params: {
                    id: projectId
                }
            });
        },
        modalCreateProjectShow() {
            this.modals.createProject.show = true;
            this.modals.createProject.newProject.name = "";
        },
        modalCreateProjectCancel() {
            this.modals.createProject.show = false;
            this.modals.createProject.newProject.name = "";
        },
        modalCreateProjectValidate() {
            const newProjectName = this.modals.createProject.newProject.name;
            const newInfoOpName = this.modals.createProject.newProject.newInfoOpName;
            const newInfoOpAddress = this.modals.createProject.newProject.newInfoOpAddress;

            this.$apollo.mutate(
                {
                    mutation: MUTATION_CREATE_PROJECT,
                    variables: {
                        newInfoOpAddress,
                        newInfoOpName,
                        newProjectName,
                    },
                    update: (store, { data: { projectCreate }  }) => {
                        // Read the data from our cache for this query.
                        let data = store.readQuery({query: ALL_PROJECTS_QUERY})

                        const p = projectCreate.project

                        // Add new project from the mutation to cache
                        data = {
                            ...data,
                            projects: [ ...data.projects, p ],
                        }

                        // Write our data back to the cache.
                        store.writeQuery({query: ALL_PROJECTS_QUERY, data})

                        kalmStore.commit("initializeLocalProject", {
                            projectId: p.id,
                            lots: p.lots,
                            locations: p.locations,
                            contacts: p.contacts,
                            name: p.name,
                            address: p.address,
                            infoOpName: p.infoOpName,
                            importantInfo: p.importantInfo,
                            archived: p.archived,
                            newProject: JSON.parse(p.document),
                            featureMultiLocation: p.featureMultiLocation,
                            currentPhase: p.currentPhase,
                            phases: JSON.parse(p.phases) || [],
                        })
                    },
                }
            )

            this.modalCreateProjectCancel()
        },
    },
    computed: {
        isModalActive() {
            return kalmStore.state.display.profileToggled
                || this.modals.createProject.show
                || this.modals.duplicateProject.show
                || this.modals.archiveProject.show
                || this.modals.unarchiveProject.show
                || this.modals.deleteProject.show;
        },
        sortedProjects() {
            return JSON.parse(JSON.stringify(this.projects)).sort(function(a, b){
                if(''+a.name < ''+b.name) return -1
                if(''+a.name > ''+b.name) return 1
                return 0
            })
        }
    },
    data() {
        return {
            modals: {
                createProject: {
                    show: false,
                    newProject: {
                        name: "",
                        newInfoOpName: "",
                        newInfoOpAddress: "",
                    }
                },
                duplicateProject: {
                    show: false,
                    oldProjectId: "",
                    duplicateLocations: true,
                    duplicateContacts: true,
                    duplicateQts: true,
                    newProject: {
                        name: "",
                        newInfoOpName: "",
                        newInfoOpAddress: "",
                    }
                },
                archiveProject: {
                    show: false,
                    projectId: "",
                },
                unarchiveProject: {
                    show: false,
                    projectId: "",
                },
                deleteProject: {
                    show: false,
                    projectId: "",
                }
            },
            projects: [],
            libraries: [],
        }
    }
}
</script>

<style scoped>

</style>