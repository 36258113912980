<template>
  <!--begin::Content-->
  <div :class="{'blur-content': this.isModalActive}">
    <div class="print-hidden">
      <ProjectBreadcrumbComponent
          title="Consultation"
          current_route="project.tender.scenarios"
          :project_id=$route.params.id
          :options="[{name: 'Suivi', id: 'project.tender.manage'},{name: 'Comparatif', id: 'project.tender.table'},{name: 'Scénarios', id: 'project.tender.scenarios'},]"
      ></ProjectBreadcrumbComponent>
    </div>
    <!--begin::Row-->
    <div class="row print-hidden" >
      <div class="col-12">
        <ProgressCard
            card_type="kalm"
            card_title="DCE • Consultation"
            :card_text_line_1="'' + this.card_count_proposals_sent + ((this.card_count_proposals_sent !== 1) ? ' descriptifs envoyés' : ' descriptif envoyé')"
            :card_text_line_2="'' + this.card_count_proposals_received + ((this.card_count_proposals_received !== 1) ? ' chiffrages reçus' : ' chiffrage reçu')"
            :card_progress_percent=(card_count_proposals_sent+card_count_proposals_received)/this.card_count_proposals_created*50>
        </ProgressCard>
      </div>
    </div>
    <PrintHeader pageTitle="Consultation des entreprises" sectionTitle="Scénarios d'attribution des lots"></PrintHeader>
    <!--end::Row-->
    <h2 class="text-dark fw-bold fs-1 mb-5 print-hidden">Scénarios d'attribution</h2>
    <!--begin::Row-->
    <div class="row col-12 g-0">
      <!--begin::Card-->
      <div class="card container px-2">
        <div class="card-toolbar py-2 w-100 text-end print-hidden">
            <button type="button"
                    @click="this.showPrintCustomText = !this.showPrintCustomText"
                    v-tooltip:bottom="'Afficher/Masquer le bloc de texte pour l\'export'"
                    class="btn btn-icon me-1 btn-active-light btn-color-muted fw-bolder"><span class="bi align-baseline bi-fonts"></span></button>
          <button type="button"
                  @click="this.toggleOrUntoggleAllLots()"
                  v-tooltip:bottom="'Tout afficher / tout masquer'"
                  class="btn btn-icon me-1 btn-active-light btn-color-muted fw-bolder"><span v-if="this.toggledLots.length === 0" class="bi align-baseline bi-arrows-expand"></span><span v-else class="bi align-baseline bi-arrows-collapse"></span></button>
          <button type="button"
                  @click="this.printDocument()"
                  class="btn me-1 btn-active-light btn-color-muted fw-bolder"
                  v-tooltip:bottom="`Tip : Changez le format d'impression en mode Paysage pour afficher plus de Scénarios par export`"
          ><span class="bi align-baseline bi-download"> Télécharger</span></button>
          <button type="button"
                  @click="this.modalCreateScenarioShow()"
                class="btn me-1 btn-active-light btn-color-muted fw-bolder">+ Ajouter un scénario</button>
        </div>
          <div>
              <div class="w-100 print-hidden" v-if="this.showPrintCustomText">
                  <textarea v-model="this.printCustomText" class="form-control" placeholder="Texte non sauvegardé qui sera affiché à l'impression"></textarea>
              </div>
              <p class="d-print-block d-none text-center" style="white-space: pre-wrap;">{{ this.printCustomText }}</p>
          </div>
        <div class="card-body pb-5 d-flex">
          <!--begin::Table-->
          <table class="table h-1px mb-0" style="table-layout: fixed; word-wrap: break-word;">
            <!--begin::head-->
            <thead>
            <tr class="fw-bolder fs-6 text-gray-800 h-100 p-0">
              <th class="h-100 mw-25" style="min-width: 150px;"></th>
              <th v-for="scenario in this.scenarios"
                  :key="scenario.id"
                  class="h-100 w-150px px-2 py-0 web-sticky">
                <div class="rounded-top overflow-hidden h-100 w-100 p-4 border border-primary border-bottom-0 text-center text-white bg-primary">
                  {{ scenario.name }}
                </div>
              </th>
            </tr>
            </thead>
            <!--end::head-->

            <!--begin::body-->
            <tbody class="h-100">
            <!--begin::emptyRow-->
            <tr class="h-100 bg-white">
              <td class="h-100 w-100"></td>
              <td v-for="scenario in this.scenarios" :key="scenario.id" class="d-table-cell h-100 px-2 py-0">
                <div class="h-100 w-100 border-start border-end border-1 border-primary text-center"></div>
              </td>
            </tr>
            <!--end::emptyRow-->

            <!--begin::tableContent-->
            <template v-for="lot in this.lots" :key="lot.id">
              <!--begin:lotHeader-->
              <tr class="h-100 lot-header">
                <td class="py-2 h-100">
                  <div class="d-flex" :class="'ms-'+(lot.number.match(/\./g) || []).length*4">
                    <h3 v-if="this.proposals && this.proposals[0]?.chiffrages.filter(c => c?.lot?.id === lot?.id).length !== 0" @click="this.toggleLot(lot.id)"
                        class="cursor-pointer align-self-center">
                      <span>
                        <i class="bi me-2" :class="this.lotToggled(lot.id) ? 'bi-arrow-down text-primary' : 'bi-arrow-right'"></i>
                      </span>
                      {{ lot.fullName }}
                    </h3>
                    <h3 v-else
                        class="align-self-center" v-tooltip:bottom="'Aucun ouvrage pour ce lot'">
                        <span>
                          <i class="bi me-2 bi-arrow-right"></i>
                        </span>
                      {{ lot.fullName }}
                    </h3>
                  </div>
                </td>
                <td v-for="scenario in this.scenarios" :key="scenario.id" class="d-table-cell px-2 py-0 h-100">
                  <div class="h-100 w-100 d-flex flex-column justify-content-center border-start border-end border-1 border-primary text-center text-dark fw-bold">
                    <div v-if="this.getProposalForScenarioLot(scenario.id, lot.id)" class="align-self-center pt-2">{{ this.displayScenarioLotSubtotal(scenario.id, lot.id) }}</div>
                    <div v-else class="align-self-center pt-2">—</div>
                    <div v-if="this.getProposalForScenarioLot(scenario.id, lot.id)" class="align-self-center text-gray-600 fw-light">{{ this.getProposalForScenarioLot(scenario.id, lot.id)?.name || this.getProposalForScenarioLot(scenario.id, lot.id)?.contact?.company || this.getProposalForScenarioLot(scenario.id, lot.id)?.contact?.name }}</div>
                    <div v-else class="align-self-center text-gray-600 fw-light">Non attribué</div>
                  </div>
                </td>
              </tr>
              <!--end:lotHeader-->
              <!--begin:lotContent-->
              <template v-if="this.lotToggled(lot.id)">
                <template v-for="(chiffrage, index) in getChiffragesForLot(lot)" :key="'c'+chiffrage.id">
                    <tr v-if="chiffrage?.groupTitle !== getChiffragesForLot(lot)[index-1]?.groupTitle" :key="chiffrage.id">
                        <td class="text-uppercase text-primary fw-light">{{ chiffrage?.groupTitle}}</td>
                        <td v-for="scenario in this.scenarios" :key="scenario.id" class="d-table-cell px-2 py-0 h-100">
                            <div class="h-100 w-100 border-start border-end border-1 border-primary text-center" style="height: 100%;"></div>
                        </td>
                    </tr>
                    <tr class="h-100 lot-content">
                        <td class="py-0">
                            <p>
                      <span @mouseenter="showOuvrageInfoTooltip(chiffrage.ouvrageId, true)"
                            @mouseleave="showOuvrageInfoTooltip(chiffrage.ouvrageId, false)"
                            class="me-2 bi bi-info-circle text-muted">
                        <span v-if="chiffrage.ouvrageId === this.tooltip" style="position: absolute; z-index: 1000;" class="ms-2 bg-white text-gray-800 p-5 border border-primary rounded">
                          <span v-if="chiffrage.numero" class="font-monospace text-muted me-2">{{ chiffrage?.numero }}</span>
                          <span class="fw-bolder text-black">{{ chiffrage.title }}</span>
                          <span v-if="chiffrage.description" style="white-space: pre-wrap;"><br>Description : {{chiffrage.description}}</span>
                          <span v-if="chiffrage.qt"><br>Quantité prévue : {{chiffrage.qt}}</span>
                          <span v-if="this.currentProjectFocus.featureMultiLocation && chiffrage.locations.map(l => l.name)"><br>Emplacement : {{ chiffrage.locations.map(l => l.name).join(", ") || "Sans emplacement"}}</span>
                          <span v-else-if="chiffrage.location?.name"><br>Emplacement : {{chiffrage.location?.name}}</span>
                        </span>
                      </span>
                                <span v-if="chiffrage?.numero" class="me-2 font-monospace text-muted">{{ chiffrage.numero }}</span>
                                {{ chiffrage.title }}
                            </p>
                        </td>
                        <td v-for="scenario in this.scenarios" :key="scenario.id" class="d-table-cell px-2 py-0 h-100">
                            <div class="h-100 w-100 border-start border-end border-1 border-primary text-center" style="height: 100%;">
                                <span v-if="this.getProposalForScenarioLot(scenario.id, lot.id)">{{ parseFloat(this.getProposalForScenarioLot(scenario.id, lot.id).chiffrages.find(c => c.ouvrageId === chiffrage.ouvrageId)?.value || "0").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € HT"}}</span>
                                <span v-else>—</span>
                            </div>
                        </td>
                    </tr>
                </template>
              </template>
              <!--end:lotContent-->
            </template>
            <!--end::tableContent-->

            <!--begin:total-->
            <tr class="h-100 lot-header">
              <td class="text-end">Total HT</td>
              <td v-for="scenario in this.scenarios" :key="scenario.id" class="d-table-cell align-middle px-2 py-0 h-100">
                <div style="border-right: 1px solid #195D5C !important; border-left: 1px solid #195D5C !important;" class="cell h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold">
                  <div class="align-self-center">
                    {{ this.scenarioTotal(scenario.id).replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} € HT
                  </div>
                </div>
              </td>
            </tr>
            <!--end:total-->

            <!--begin:total-->
            <tr class="h-100 lot-header">
              <td class="text-end">TVA</td>
              <td v-for="scenario in this.scenarios" :key="scenario.id" class="d-table-cell align-text-bottom px-2 py-0 h-100">
                <div style="border-right: 1px solid #195D5C !important; border-left: 1px solid #195D5C !important;" class="cell h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold">
                  <div class="align-self-center">
                    {{ this.scenarioVatTotal(scenario.id).replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} €
                  </div>
                </div>
              </td>
            </tr>
            <!--end:total-->

            <!--begin:total-->
            <tr class="h-100 lot-header">
              <td class="text-end">Total TTC</td>
              <td v-for="scenario in this.scenarios" :key="scenario.id" class="d-table-cell align-middle px-2 py-0 h-100">
                <div style="border-right: 1px solid #195D5C !important; border-left: 1px solid #195D5C !important;" class="cell h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold">
                  <div class="align-self-center">
                    {{ (parseFloat(this.scenarioTotal(scenario.id)) + parseFloat(this.scenarioVatTotal(scenario.id))).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} € TTC
                  </div>
                </div>
              </td>
            </tr>
            <!--end:total-->

            <!--begin:roundedBottom-->
            <tr>
              <td></td>
              <td v-for="scenario in this.scenarios" :key="scenario.id" class="px-2 py-0 h-100">
                <div @click="modalUpdateScenarioShow(scenario.id)"
                     class="edit-proposal-button rounded-bottom h-100 w-100 border border-primary border-top-0 text-center text-white bg-primary p-2">
                  <i class="bi print-hidden bi-pencil-fill text-white"></i>
                </div>
              </td>
            </tr>
            <!--end:roundedBottom-->
            </tbody>
            <!--end::body-->
          </table>
          <!--end::Table-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Row-->
  </div>
  <!--end::Content-->

  <!--begin::Modal Create Scenario-->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="modalCreateScenarioClose()"
       :style="this.modals.createScenario.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-700px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalCreateScenarioClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-600px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Ajouter un scénario d'attribution</h3>
                      </div>
                      <div>
                        <label>Nom du scénario</label>
                        <input class="form-control form-control-lg form-control-solid" v-model="this.modals.createScenario.name">
                      </div>
                      <div class="">
                        <div v-for="lot in this.lots" :key="lot.id" class="mt-4">
                          <label>{{ lot.fullName }} — Entreprise attribuée</label>
                          <select v-model="this.modals.createScenario.graph[lot.id]" class="form-select form-select-lg form-select-solid fw-bold" v-if="this.proposals">
                            <option v-for="proposal in [...this.proposals, {id: '', contact: {name: 'Lot non attribué'}}]" :value="proposal.id" :key="proposal.id">{{ proposal.name || proposal.contact?.company || proposal.contact.name }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="this.modalCreateScenarioClose()" class="me-2 btn btn-lg btn-light-primary btn-active-accent">
                        Annuler
                      </button>
                      <button type="button"
                              @click="this.modalCreateScenarioValidate()"
                              :disabled="Object.keys(this.modals.createScenario.graph).length !== this.lots.length
                                          || !this.modals.createScenario.name"
                              class="btn btn-lg btn-primary btn-active-light-primary">
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--begin::Modal Update Scenario-->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="modalUpdateScenarioClose()"
       :style="this.modals.updateScenario.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-700px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalUpdateScenarioClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-600px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Modifier le scénario d'attribution</h3>
                      </div>
                      <div>
                        <label>Nom du scénario</label>
                        <input class="form-control form-control-lg form-control-solid" v-model="this.modals.updateScenario.name">
                      </div>
                      <div class="">
                        <div v-for="lot in this.lots" :key="lot.id" class="mt-4">
                          <label>{{ lot.fullName }} — Entreprise attribuée</label>
                          <select v-model="this.modals.updateScenario.graph[lot.id]" class="form-select form-select-lg form-select-solid fw-bold" v-if="this.proposals">
                            <option v-for="proposal in [...this.proposals, {id: '', contact: {name: 'Lot non attribué'}}]" :value="proposal.id" :key="proposal.id">{{ proposal.name || proposal.contact.company || proposal.contact.name }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="this.modalDeleteScenarioShow(this.modals.updateScenario.scenarioId)" class="me-2 btn btn-lg btn-outline-danger btn-active-bg-accent btn-active-color-danger">
                        Supprimer
                      </button>
                      <button type="button" @click="this.modalUpdateScenarioClose()" class="me-2 btn btn-lg btn-light-primary btn-active-accent">
                        Annuler
                      </button>
                      <button type="button" @click="this.modalUpdateScenarioValidate()" class="btn btn-lg btn-primary btn-active-light-primary">
                        Enregistrer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- CONFIRM DELETE SCENARIO -->
  <div class="modal"
       tabindex="-1"
       :style="modals.deleteScenario.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalDeleteScenarioClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Supprimer le scénario ?</h3>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="this.modalDeleteScenarioClose()" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="this.modalDeleteScenarioValidate()" class="btn btn-lg btn-primary ms-2">
                        Supprimer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
CURRENT_USER_QUERY,
GET_PROPOSALS_FOR_PROJECT,
GET_SCENARIOS_FOR_PROJECT,
MUTATION_TENDER_CREATE_SCENARIO,
MUTATION_TENDER_DELETE_SCENARIO,
MUTATION_TENDER_UPDATE_SCENARIO,
} from "@/graphql/graphql";
import kalmStore from "@/store";
import ProgressCard from "@/views/_core/components/ProgressCard";
import { ALL_CONTACTS_QUERY } from "@/views/contacts/data/contacts_graphql";
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent";
import PrintHeader from "@/views/project/tender/PrintHeader";
import { toast } from 'vue3-toastify';

export default {
  name: "ScenarioPage",
  components: { ProgressCard, ProjectBreadcrumbComponent, PrintHeader },
  apollo: {
    contacts: {
      query: ALL_CONTACTS_QUERY,
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
    proposals: {
      query: GET_PROPOSALS_FOR_PROJECT,
      variables() {
        return {
          projectId: this.currentProjectFocus,
        };
      },
    },
    scenarios: {
      query: GET_SCENARIOS_FOR_PROJECT,
      variables() {
        return {
          projectId: this.currentProjectFocus,
        };
      },
    },
  },
  computed: {
    isModalActive: function() {
      return kalmStore.state.display.profileToggled
          || this.modals.createScenario.show
          || this.modals.updateScenario.show
          || this.modals.deleteScenario.show;
    },
    lots(){
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).lots
    },
    storeIsReady() {
      return kalmStore.getters.currentProjectFocus
          && kalmStore.getters.currentProjectFocus !== "0"
          && kalmStore.getters.currentProjectFocus !== 0
    },
    currentProjectFocus() {
      return kalmStore.getters.currentProjectFocus;
    },
    card_count_proposals_created(){
      if (!this.proposals) return 0;

      return this.proposals.length
    },
    card_count_proposals_sent(){
      if (!this.proposals) return 0;

      let ret = 0;
      this.proposals.forEach(p => {
        ret += JSON.parse(p.steps).find(s => s.id === "1")?.status ? 1 : 0;
      })
      return ret
    },
    card_count_proposals_received(){
      if (!this.proposals) return 0;

      let ret = 0;
      this.proposals.forEach(p => {
        ret += JSON.parse(p.steps).find(s => s.id === "2")?.status ? 1 : 0;
      })
      return ret
    },
  },
  methods: {
      getChiffragesForLot(lot) {
          return this.proposals[0]?.chiffrages.filter(c => c.lot.id === lot.id).sort(function(a, b) {
              if (a.order < b.order) { return -1; }
              if (a.order > b.order) { return 1; }
              return 0;
          });
      },
    toggleOrUntoggleAllLots() {
      if(this.toggledLots.length === this.lots.length){
        this.toggledLots = [];
      }
      else {
        this.lots.forEach(l => {
          this.toggledLots.push(l.id);
        })
      }
    },
    printDocument() {
      window.print();
    },
    modalCreateScenarioShow(){
      this.modals.createScenario.graph = {};
      this.modals.createScenario.name = "";
      this.modals.createScenario.show = true;
    },
    modalCreateScenarioClose(){
      this.modals.createScenario.show = false;
      this.modals.createScenario.name = "";
      this.modals.createScenario.graph = {};
      this.modals.createScenario.scenarioId = "";
    },
    async modalCreateScenarioValidate(){
      const projectId = this.currentProjectFocus;
      const name = this.modals.createScenario.name;
      const graph = JSON.stringify(this.modals.createScenario.graph);
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_TENDER_CREATE_SCENARIO,
          variables: {
            projectId,
            name,
            graph
          },
          refetchQueries: [
            {
              query: GET_SCENARIOS_FOR_PROJECT,
              variables: {
                projectId
              }
            }
          ]
        })
        toast.success("Scénario ajouté avec succès")
      } catch (e) {
        toast.error("Erreur lors de l'ajout du scénario")
      }
      this.modalCreateScenarioClose();
    },
    modalUpdateScenarioShow(scenarioId){
      this.modals.updateScenario.scenarioId = scenarioId;
      this.modals.updateScenario.name = this.getScenarioById(scenarioId).name;
      this.modals.updateScenario.graph = JSON.parse(this.getScenarioById(scenarioId).graph);
      this.modals.updateScenario.show = true;
    },
    modalUpdateScenarioClose(){
      this.modals.updateScenario.show = false;
      this.modals.updateScenario.name = "";
      this.modals.updateScenario.graph = {};
      this.modals.updateScenario.scenarioId = "";
    },
    async modalUpdateScenarioValidate(){
      const name = this.modals.updateScenario.name;
      const scenarioId = this.modals.updateScenario.scenarioId;
      const graph = JSON.stringify(this.modals.updateScenario.graph);
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_TENDER_UPDATE_SCENARIO,
          variables: {
            scenarioId,
            name,
            graph
          },
          refetchQueries: [
            {
              query: GET_SCENARIOS_FOR_PROJECT,
              variables: {
                projectId: this.currentProjectFocus
              }
            }
          ]
        })
        toast.success("Scénario modifié avec succès")
      } catch (e) {
        toast.error("Erreur lors de la modification du scénario")
      }
      this.modalUpdateScenarioClose();
    },
    modalDeleteScenarioShow(scenarioId) {
      this.modals.deleteScenario.show = true;
      this.modals.updateScenario.show = false;
      this.modals.deleteScenario.scenarioId = scenarioId;
    },
    modalDeleteScenarioClose() {
      this.modals.deleteScenario.show = false;
      this.modals.updateScenario.show = true;
    },
    async modalDeleteScenarioValidate() {
      this.modals.deleteScenario.show = false;
      const scenarioId = this.modals.deleteScenario.scenarioId;
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_TENDER_DELETE_SCENARIO,
          variables: {
            scenarioId
          },
          refetchQueries: [
            {
              query: GET_SCENARIOS_FOR_PROJECT,
              variables: {
                projectId: this.currentProjectFocus
              }
            }
          ]
        })
        toast.success("Scénario supprimé avec succès")
      } catch (e) {
        toast.error("Erreur lors de la suppression du scénario")
      }
      this.modals.deleteScenario.scenarioId = "";
    },
    getProposalById(proposalId){
      if (!this.proposals) return null;

      return this.proposals.find(p => p.id === proposalId);
    },
    getScenarioById(scenarioId){
      if (!this.scenarios) return null;

      return this.scenarios.find(s => s.id === scenarioId);
    },
    getProposalForScenarioLot(scenarioId, lotId){
      if (!this.proposals || !this.scenarios) return null;

      return this.getProposalById(JSON.parse(this.getScenarioById(scenarioId).graph)[lotId]);
    },
    proposalSubtotal(scenarioId, proposalId, lotId) {
      const proposal = this.getProposalById(proposalId);

      let lotSubTotal = 0;

      if (!proposal || !proposal.lots.map(l => l.id).includes(lotId)) {

        const masterLot = this.lots.find(l => l.id === lotId);

        if(masterLot && this.lots.filter(l => l.number.startsWith(masterLot.number+'.') && l.number !== masterLot.number && l.id !== lotId && l.number.split(".").length - 1 === masterLot.number.split(".").length).length) {
          const subLots = this.lots.filter(l => l.number.startsWith(masterLot.number+'.') && l.number !== masterLot.number && l.id !== lotId && l.number.split(".").length - 1 === masterLot.number.split(".").length);
          for(const x in subLots) {
            const l = subLots[x];
            const proposalForSouslot = JSON.parse(this.getScenarioById(scenarioId).graph)[l.id];
            lotSubTotal += this.proposalSubtotal(scenarioId, proposalForSouslot, l.id);
          }
        }

      }
      else {
        if(JSON.parse(proposal.lotGlobalValues)[lotId]?.sum) {
          lotSubTotal = JSON.parse(JSON.parse(proposal.lotGlobalValues)[lotId].sum);
        }
        else {
          lotSubTotal = proposal.chiffrages.filter(c => c.lot.id === lotId)
              .map(c => {
                const ret = parseFloat(c?.value)
                return isNaN(ret) ? 0 : parseFloat(c?.value)
              })
              .reduce((a,b) => a + b, 0)

          const masterLot = proposal.lots.find(l => l.id === lotId);

          if(masterLot && proposal.lots.filter(l => l.number.startsWith(masterLot.number+'.') && l.number !== masterLot.number && l.id !== lotId && l.number.split(".").length - 1 === masterLot.number.split(".").length).length) {
            const subLots = proposal.lots.filter(l => l.number.startsWith(masterLot.number+'.') && l.number !== masterLot.number && l.id !== lotId && l.number.split(".").length - 1 === masterLot.number.split(".").length);
            for(const x in subLots) {
              const l = subLots[x];
              lotSubTotal += this.proposalSubtotal(scenarioId, proposalId, l.id);
            }
          }
        }
      }
      return lotSubTotal
    },
    proposalSubtotalFormatted(scenarioId, proposalId, lotId) {
      if (!this.proposals) return "NC";

      const proposal = this.getProposalById(proposalId);
      if(proposal.lots.map(l => l.id).includes(lotId)){
        let lotSubTotal = this.proposalSubtotal(scenarioId, proposalId, lotId)

        return lotSubTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € HT";
      }
      else {
        return proposal?.contact ? "NC" : "NC"
      }
    },
    scenarioTotal(scenarioId) {
      let totalHT = 0;
      const graph = JSON.parse(this.getScenarioById(scenarioId).graph);

      this.lots.filter(l => l.number.split(".").length - 1 === 0).forEach(lot => {
        const proposalForLot = graph[lot.id]
        totalHT += parseFloat(this.proposalSubtotal(scenarioId, proposalForLot, lot.id) || 0)
      })

      return totalHT.toFixed(2);
    },
    scenarioVatTotal(scenarioId) {
      let totalVAT = 0;
      const graph = JSON.parse(this.getScenarioById(scenarioId).graph);

      this.lots.filter(l => l.number.split(".").length - 1 === 0).forEach(lot => {

        const proposalForLot = graph[lot.id]
        totalVAT += parseFloat(this.proposalVatSubtotal(scenarioId, proposalForLot, lot.id) || 0)
      })

      return totalVAT.toFixed(2);
    },
    proposalVatSubtotal(scenarioId, proposalId, lotId) {
      const proposal = this.getProposalById(proposalId);

      let lotSubTotal = 0;

      if (!proposal || !proposal.lots.map(l => l.id).includes(lotId)) {

        const masterLot = this.lots.find(l => l.id === lotId);

        if(masterLot && this.lots.filter(l => l.number.startsWith(masterLot.number+'.') && l.number !== masterLot.number && l.id !== lotId && l.number.split(".").length - 1 === masterLot.number.split(".").length).length) {
          const subLots = this.lots.filter(l => l.number.startsWith(masterLot.number+'.') && l.number !== masterLot.number && l.id !== lotId && l.number.split(".").length - 1 === masterLot.number.split(".").length);
          for(const x in subLots) {
            const l = subLots[x];
            const proposalForSouslot = JSON.parse(this.getScenarioById(scenarioId).graph)[l.id];
            lotSubTotal += this.proposalVatSubtotal(scenarioId, proposalForSouslot, l.id);
          }
        }

      }
      else {
        if(JSON.parse(proposal.lotGlobalValues)[lotId]?.sum) {
          const lotVatRate = (JSON.parse(proposal.lotGlobalValues)[lotId].vat === null
              || JSON.parse(proposal.lotGlobalValues)[lotId].vat === ""
              || isNaN(JSON.parse(proposal.lotGlobalValues)[lotId].vat)
          ) ? proposal.vatDefaultValue : JSON.parse(proposal.lotGlobalValues)[lotId].vat;
          lotSubTotal = JSON.parse(proposal.lotGlobalValues)[lotId].sum * lotVatRate / 100;
        }
        else {
          if(proposal.lots.map(l => l.id).includes(lotId)) {
            lotSubTotal = proposal.chiffrages.filter(c => c.lot.id === lotId)
                .map(c => {
                  const ret = parseFloat(c?.vatValue)
                  return isNaN(ret) ? 0 : parseFloat(c?.vatValue)
                })
                .reduce((a,b) => a + b, 0)
          }

          const masterLot = this.lots.find(l => l.id === lotId);

          if(masterLot && this.lots.filter(l => l.number.startsWith(masterLot.number+'.') && l.number !== masterLot.number && l.id !== lotId && l.number.split(".").length - 1 === masterLot.number.split(".").length).length) {
            const subLots = this.lots.filter(l => l.number.startsWith(masterLot.number+'.') && l.number !== masterLot.number && l.id !== lotId && l.number.split(".").length - 1 === masterLot.number.split(".").length);
            for(const x in subLots) {
              const l = subLots[x];
              const proposalForSouslot = JSON.parse(this.getScenarioById(scenarioId).graph)[l.id];
              lotSubTotal += this.proposalVatSubtotal(scenarioId, proposalForSouslot, l.id);
            }
          }
        }
      }
      return lotSubTotal
    },
    toggleLot(lotId) {
      if(this.toggledLots.includes(lotId)) {
        this.toggledLots = [...this.toggledLots.filter(l => l !== lotId)]
      }
      else {
        this.toggledLots.push(lotId)
      }
    },
    lotToggled(lotId) {
      return this.toggledLots.includes(lotId)
    },
    showOuvrageInfoTooltip(ouvrageId, status){
      this.tooltip = status ? ouvrageId : '';
    },
    proposalStrictSubtotal(proposalId, lotId) {
      return this.getProposalById(proposalId)?.chiffrages?.filter(c => c.lot.id === lotId)
          .map(c => {
            const ret = parseFloat(c?.value)
            return isNaN(ret) ? 0 : parseFloat(c?.value)
          })
          .reduce((a, b) => a + b, 0)
    },
    displayScenarioLotSubtotal(scenarioId, lotId) {
      const graph = JSON.parse(this.getScenarioById(scenarioId).graph);

      if(graph[lotId]) {
        const proposal = this.getProposalForScenarioLot(scenarioId, lotId);
        if(proposal) {
          return this.proposalSubtotalFormatted(scenarioId, graph[lotId], lotId);
        }
        else {
          return "-"
        }
      }
      else {
        return "—"
      }
    }
  },
  data() {
    return {
        showPrintCustomText: false,
        printCustomText: "",
      lines: [],
      contacts: [],
        proposals: [],
        scenarios: [],
      modals: {
        updateScenario: {
          show: false,
          scenarioId: "",
          graph: {},
          name: "",
        },
        createScenario: {
          show: false,
          graph: {},
          name: "",
        },
        deleteScenario: {
          show: false,
          scenarioId: "",
        }
      },
      tooltip: '',
      toggledLots: []
    }
  },
}
</script>

<style scoped>
  .edit-proposal-button {
    cursor: pointer;
  }
  .edit-proposal-button:hover, .edit-proposal-button:hover i {
    cursor: pointer;
    background-color: white !important;
    color: gray !important;
  }

  @media print {
    .print-hidden {
      display: none;
    }

    .print-noindent {
      margin-left: 0 !important;
    }

    tr, th {
      break-inside: avoid !important;
    }

    table {
      break-before: avoid;
    }
  }

  @media screen {
    .web-sticky {
      position: sticky;
      top: 60px;
    }
  }
</style>