import gql from 'graphql-tag';

export const GET_TODOS = gql`
  query TodosByProject($projectId: UUID, $assignedToCurrentUser: Boolean) {
    todosByProject(projectId: $projectId, assignedToCurrentUser: $assignedToCurrentUser) {
      id
      title
      order
      createdAt
      updatedAt
      project {
        id,
        name,
      }
      todoItems(assignedToCurrentUser: $assignedToCurrentUser) {
        id
        name
        description
        color
        dueDate
        isDone
        order
        assignedTo {
          id
          firstName
          lastName
          photo
          color
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_TODO = gql`
  mutation TodoCreate(
    $title: String!,
    $order: Int!,
    $projectId: UUID
  ) {
    todoCreate(
      title: $title,
      order: $order,
      projectId: $projectId
    ) {
      todo {
        id
        title
        order
        project {
          id
        }
      }
    }
  }
`;

export const CREATE_TODO_ITEM = gql`
  mutation TodoItemCreate(
    $name: String!,
    $todoId: UUID!
    $order: Int!,
    $description: String,
    $color: String,
    $dueDate: Date,
    $isDone: Boolean,
    $todoGroupId: UUID
    $assignedToId: UUID
  ) {
    todoItemCreate(
      name: $name,
      description: $description,
      color: $color,
      dueDate: $dueDate,
      isDone: $isDone,
      order: $order,
      todoId: $todoId,
      todoGroupId: $todoGroupId
      assignedToId: $assignedToId
    ) {
      todoItem {
        id
        name
        description
        color
        dueDate
        isDone
        order
      }
    }
  }
`;

export const UPDATE_TODO = gql`
  mutation TodoUpdate(
    $id: UUID!,
    $order: Int,
    $title: String,
  ) {
    todoUpdate(
      id: $id,
      title: $title,
      order: $order,
    ) {
      todo {
        id
        title
        order
      }
    }
  }
`;

export const BULK_UPDATE_TODO_ORDER = gql`
  mutation BulkUpdateTodoOrder($todos: [JSONString!]!) {
    bulkUpdateTodoOrder(todos: $todos) {
      success
    }
  }
`;

export const UPDATE_TODO_ITEM = gql`
  mutation TodoItemUpdate(
    $id: UUID!,
    $name: String,
    $description: String,
    $color: String,
    $dueDate: Date,
    $isDone: Boolean,
    $order: Int,
    $todoGroupId: UUID
    $assignedToId: String
  ) {
    todoItemUpdate(
      id: $id,
      name: $name,
      description: $description,
      color: $color,
      dueDate: $dueDate,
      isDone: $isDone,
      order: $order,
      todoGroupId: $todoGroupId
      assignedToId: $assignedToId
    ) {
      todoItem {
        id
        name
        description
        color
        dueDate
        isDone
        order
      }
    }
  }
`;

export const BULK_UPDATE_TODO_ITEM_ORDER = gql`
  mutation BulkUpdateTodoItemOrder($todoItems: [JSONString!]!) {
    bulkUpdateTodoItemOrder(todoItems: $todoItems) {
      success
    }
  }
`;

export const DELETE_TODO = gql`
  mutation TodoDelete($id: UUID!) {
    todoDelete(id: $id) {
        deleted
    }
  }
`;

export const DELETE_TODO_ITEM = gql`
  mutation TodoItemDelete($id: UUID!) {
    todoItemDelete(id: $id) {
        deleted
    }
  }
`;

export const GET_TODO_ITEM_BY_ID = gql`
  query TodoItemById($todoItemId: UUID!) {
    todoItem: todoItemById(todoItemId: $todoItemId) {
      id
      name
      description
      color
      dueDate
      isDone
      order
      createdAt
      updatedAt
      files {
        id
        name
        url
      }
    }
  }
`;