import gql from 'graphql-tag'

export const MUTATION_CONTACT_CREATE = gql`
    mutation ($newContact: ContactInput!,)
    {
        contactCreate(
            newContact: $newContact,
        )
        {
            contact {
                id,
                name,
                email,
                phone,
                phone2,
                type,
                company,
                infoSiret,
                infoCorps,
                infoWebsite,
                infoInsurance,
                infoComment,
                infoAddress,
                infoTva,
                proposals {
                    id,
                    name,
                    project {
                        id,
                        name,
                    }
                },
                infoLine1,
                infoLine2,
            }
        }
    }
`

export const MUTATION_CONTACT_UPDATE = gql`
    mutation (
        $contactId: String!,
        $newContact: ContactInput!,
    )
    {
        contactUpdate(
            contactId: $contactId,
            newContact: $newContact,
        )
        {
            contact {
                id,
                name,
                email,
                phone,
                phone2,
                type,
                company,
                infoSiret,
                infoCorps,
                infoWebsite,
                infoInsurance,
                infoComment,
                infoAddress,
                infoTva,
                proposals {
                    id,
                    name,
                    project {
                        id,
                        name,
                    }
                },
                infoLine1,
                infoLine2,
            }
        }
    }
`

export const MUTATION_CONTACT_DELETE = gql`
    mutation ($contactId: String!)
    {
        contactDelete(
            contactId: $contactId
        )
        {
            deleted
        }
    }
`

export const MUTATION_ASSIGN_CONTACT = gql`
    mutation(
        $projectId: UUID!,
        $contactId: UUID!,
        $role: String!,
    )
    {
        projectAssignContact(
            projectId: $projectId,
            contactId: $contactId,
            role: $role,
        )
        {
            project {
                id,
                name,
                contacts {
                    role,
                    contact {
                        id,
                        name,
                        email,
                        phone,
                        type,
                        company,
                        infoLine1,
                        infoLine2,
                    }
                }
            }
        }
    }
`

export const MUTATION_UNASSIGN_CONTACT = gql`
    mutation(
        $projectId: UUID!,
        $contactId: UUID!,
    )
    {
        projectUnassignContact(
            projectId: $projectId,
            contactId: $contactId,
        )
        {
            project {
                id,
                name,
                contacts {
                    role,
                    contact {
                        id,
                        name,
                        email,
                        phone,
                        type,
                        company,
                        infoLine1,
                        infoLine2,
                    }
                }
            }
        }
    }
`

export const ALL_CONTACTS_QUERY = gql`
    query AllContactsQuery {
        contacts: allContacts {
            id,
            email,
            name,
            phone,
            phone2,
            company,
            type,
            infoSiret,
            infoCorps,
            infoWebsite,
            infoInsurance,
            infoComment,
            infoAddress,
            infoTva,
            proposals {
                id,
                name,
                project {
                    id,
                    name,
                }
            },
            infoLine1,
            infoLine2,
        }
    }
`

export const PROJECT_CONTACTS = gql`
    query ProjectContacts($projectId: UUID!) {
        contacts: projectContacts(projectId: $projectId) {
            id,
            email,
            name,
            phone,
            phone2,
            company,
            type,
            infoSiret,
            infoCorps,
            infoWebsite,
            infoInsurance,
            infoComment,
            infoAddress,
            infoTva,
            infoLine1,
            infoLine2,
        }
    }
`

export const CONTACT_BY_ID = gql`
    query ContactById($contactId: UUID!) {
        contact: contactById(contactId: $contactId) {
            id,
            email,
            name,
            phone,
            phone2,
            company,
            type,
            infoSiret,
            infoCorps,
            infoWebsite,
            infoInsurance,
            infoComment,
            infoAddress,
            infoTva,
            infoLine1,
            infoLine2,
            projectParticipations {
                role,
                project {
                    id,
                    name,
                }
            },
            proposals {
                id,
                name,
                project {
                    id,
                    name,
                }
            },
            files {
                id,
                name,
                url,
                creationDate
            },
        }
    }
`