<template>
  <!--begin::Content-->
  <div :class="{'blur-content': this.isModalActive}">
    <div class="print-hidden">
      <ProjectBreadcrumbComponent
        title="Consultation"
        current_route="project.tender.table"
        :project_id=$route.params.id
        :options="[{name: 'Suivi', id: 'project.tender.manage'}, {name: 'Comparatif', id: 'project.tender.table'}, {name: 'Comparatif par lot', id: 'project.tender.table_lots'}, {name: 'Scénarios', id: 'project.tender.scenarios', disabled: !this.currentUser?.groups?.includes('plan_premium')},]"
      ></ProjectBreadcrumbComponent>
    </div>
    <!--begin::Row-->
    <PrintHeader :pageTitle="this.printOptions.title" :sectionTitle="this.printOptions.subtitle" :sectionText="this.printOptions.text"></PrintHeader>
    <!--end::Row-->
    <h2 class="text-dark fw-bold fs-1 mb-5 print-hidden">Devis reçus</h2>
    <select v-model="this.selectedLot" class="form-select form-select-solid print-hidden mb-2">
      <option disabled value="" selected>Sélectionnez un lot...</option>
      <option v-for="lot in this.currentProject.lots" :key="lot.id" :value="lot.id">{{ lot.fullName }}</option>
    </select>
    <!--begin::Row-->
    <div class="row col-12 g-0">
      <!--begin::Card-->
      <div class="card container px-2">
        <div class="card-toolbar py-2 w-100 text-end print-hidden">
          <button type="button"
                  @click="this.printTender()"
                  :disabled="!this.selectedLot"
                  class="btn me-1 btn-active-light btn-color-muted fw-bolder"><span class="bi align-baseline bi-download me-2"></span>Consultation</button>
          <button type="button"
                  :disabled="!this.selectedLot"
                  @click="this.printEstimation()"
                  class="btn me-1 btn-active-light btn-color-muted fw-bolder"><span class="bi align-baseline bi-download me-2"></span>Estimatif</button>
          <button type="button"
                  @click="this.$router.push({name: 'project.tender.manage'})"
                  class="btn me-1 btn-active-light btn-color-muted fw-bolder"><span class="bi align-baseline bi-plus"></span> Ajouter un devis</button>
        </div>
        <div class="card-body pb-5 d-flex">
          <!--begin::Table-->
          <table class="table h-1px mb-0" style="border-collapse: collapse !important;">
            <!--begin::head-->
            <thead>
            <tr class="fw-bolder fs-6 text-gray-800 h-100 p-0">
              <th class=""></th>
              <th class=""></th>
              <th class="w-20px"></th>
              <th v-for="proposal in this.proposals"
                  :key="proposal.id"
                  class="h-100 w-150px px-2 py-0 web-sticky"
                  :class="{'print-hidden': proposal.hide || proposal?.contact && this.printOptions.hideProposalsFromPrint}"
                  scope="col">
                <div class="rounded-top h-100 w-100 p-4 border border-primary border-bottom-0 text-center"
                    :class="proposal.hide ? 'bg-light text-gray-800 print-hidden' : 'bg-primary text-white'">
                  {{ proposal.name || proposal.contact.company || proposal.contact.name }}
                </div>
              </th>
            </tr>
            <tr class="fw-bolder fs-6 text-gray-800 h-100 p-0 print-hidden">
              <td class=""></td>
              <td class=""></td>
              <td class=""></td>
              <!--suppress HtmlDeprecatedAttribute -->
                <td v-for="proposal in this.proposals"
                  :key="proposal.id"
                  class="h-100 w-150px px-2 py-0"
                  :class="{'print-hidden': proposal.hide || proposal?.contact && this.printOptions.hideProposalsFromPrint}"
                  scope="col">
                <div class="h-100 w-100 border border-primary border-bottom-0 border-top-0 text-center"
                    :class="proposal.hide ? 'bg-light text-gray-800 print-hidden' : 'bg-primary text-white'">
                  <span v-if="proposal.hide" class="pt-2" @click="this.hideProposal(proposal.id, false)"><span class="bi bi-eye-slash-fill cursor-pointer text-gray-800 w-100 py-3 print-hidden"></span></span>
                  <span v-else class="pt-2" @click="this.hideProposal(proposal.id, true)"><span class="bi bi-eye-fill cursor-pointer text-white w-100 py-3 print-hidden"></span></span>
                </div>
              </td>
            </tr>
            </thead>
            <!--end::head-->

            <!--begin::body-->
            <tbody class="h-100" style="">
            <!--begin::emptyRow-->
            <tr class="h-100 bg-white" style="">
              <td class="h-100"></td>
              <td class="h-100"></td>
              <td class=""></td>
              <td v-for="proposal in this.proposals" :key="proposal.id" style="" class="d-table-cell h-100 px-2 py-0"
                  :class="{'print-hidden': proposal.hide || proposal?.contact && this.printOptions.hideProposalsFromPrint}">
                <div class="h-100 w-100 border-start border-end border-1 border-primary text-center"></div>
              </td>
            </tr>
            <!--end::emptyRow-->

            <!--begin::tableContent-->
            <template v-for="lot in this.lots" :key="lot.id">
              <!--begin:lotHeader-->
              <tr class="h-100 lot-header" :class="showLots[lot.id] ? '' : 'print-hidden'">
                <td class="py-2 h-100">
                  <div class="d-flex" :class="'ms-'+(lot.number.match(/\./g) || []).length*4">
                    <h3 v-if="this.proposals[0]?.chiffrages.filter(c => c?.lot?.id === lot?.id).length !== 0" @click="this.toggleLot(lot.id)"
                        class="cursor-pointer align-self-center"
                        :class="this.showLots[lot.id] ? 'text-dark' : 'text-muted'">
                      <span class="print-hidden">
                        <i class="bi me-2" :class="this.lotToggled(lot.id) ? 'bi-arrow-down text-primary' : 'bi-arrow-right'"></i>
                      </span>
                      {{ lot.fullName }}
                    </h3>
                    <h3 v-else
                        class="align-self-center" v-tooltip:bottom="'Aucun ouvrage pour ce lot'"
                        :class="this.showLots[lot.id] ? 'text-dark' : 'text-muted'">
                        <span class="print-hidden">
                          <i class="bi me-2 bi-arrow-right"></i>
                        </span>
                      {{ lot.fullName }}
                    </h3>
                  </div>
                </td>
                <td class="py-1 h-100"></td>
                <td class="align-middle">
                  <i class="bi cursor-pointer print-hidden"
                     :class="this.showLots[lot.id] ? 'bi-eye-fill text-grey-800' : 'bi-eye-slash-fill text-grey-600'"
                     @click="this.toggleHideLot(lot.id)"></i>
                </td>
                <td v-for="proposal in this.proposals" :key="proposal.id" class="d-table-cell px-2 py-0 h-100" :class="{'print-hidden': proposal.hide || proposal?.contact && this.printOptions.hideProposalsFromPrint}">
                  <div class="h-100 w-100 d-flex justify-content-center border-start border-end border-1 border-primary text-center text-dark fw-bold"
                       :class="this.showLots[lot.id] ? 'text-dark' : 'text-muted'">
                    <div class="align-self-center">{{ this.proposalSubtotalFormatted(proposal.id, lot.id)}}</div>
                  </div>
                </td>
              </tr>
              <!--end:lotHeader-->
              <!--begin:lotContent-->
              <template v-if="this.lotToggled(lot.id)">
                  <template v-for="(chiffrage, index) in getChiffragesForLot(lot)" :key="chiffrage.ouvrageId">
                      <tr v-if="chiffrage?.groupTitle !== getChiffragesForLot(lot)[index-1]?.groupTitle">
                          <td class="text-uppercase text-primary fw-light">{{ chiffrage?.groupTitle}}</td> {{}}
                          <td class="py-0"></td>
                          <td class=""></td>
                          <td v-for="proposal in this.proposals" :key="proposal?.id" class="d-table-cell px-2 py-0 h-100" :class="{'print-hidden': proposal.hide || proposal?.contact && this.printOptions.hideProposalsFromPrint}">
                              <div class="h-100 w-100 border-start border-end border-1 border-primary text-center" style="height: 100%;"></div>
                          </td>
                      </tr>
                      <tr class="h-100 lot-content" :class="showLots[lot.id] ? '' : 'print-hidden text-muted'">
                          <td class="py-0">
                              <p>
                      <span @mouseenter="showOuvrageInfoTooltip(chiffrage.ouvrageId, true)"
                            @mouseleave="showOuvrageInfoTooltip(chiffrage.ouvrageId, false)"
                            class="me-2 bi bi-info-circle text-muted">
                        <span v-if="chiffrage.ouvrageId === this.tooltip" style="position: absolute; z-index: 1000;" class="ms-2 bg-white text-gray-800 p-5 border border-primary rounded">
                          <span v-if="chiffrage?.numero" class="font-monospace text-muted me-2">{{ chiffrage?.numero }}</span>
                          <span class="fw-bolder text-black">{{ chiffrage.title }}</span>
                          <span v-if="chiffrage.description" style="white-space: pre-wrap;"><br>Description : {{chiffrage.description}}</span>
                          <span v-if="chiffrage.qt"><br>Quantité prévue : {{chiffrage.qt}}</span>
                          <span v-if="this.currentProjectFocus.featureMultiLocation && chiffrage.locations.map(l => l.name)"><br>Emplacement : {{ chiffrage.locations.map(l => l.name).join(", ") || "Sans emplacement"}}</span>
                          <span v-else-if="chiffrage.location?.name"><br>Emplacement : {{chiffrage.location?.name}}</span>
                        </span>
                      </span>
                                  <span v-if="chiffrage?.numero" class="me-2 font-monospace text-muted">{{ chiffrage.numero }}</span>
                                  {{ chiffrage.title }}
                              </p>
                          </td>
                          <td class="py-0"></td>
                          <td class=""></td>
                          <td v-for="proposal in this.proposals" :key="proposal.id" class="d-table-cell px-2 py-0 h-100" :class="{'print-hidden': proposal.hide || proposal?.contact && this.printOptions.hideProposalsFromPrint}">
                              <div class="h-100 w-100 border-start border-end border-1 border-primary text-center" style="height: 100%;"><span>{{ this.getProposalChiffrageForOuvrage(proposal, lot.id, chiffrage.ouvrageId) }}</span></div>
                          </td>
                      </tr>
                  </template>
              </template>
              <!--end:lotContent-->
            </template>
            <!--end::tableContent-->

            <!--begin:total-->
            <tr class="h-100 lot-header">
              <td class="text-end" colspan="3">Total HT</td>
              <td v-for="proposal in this.proposals" :key="proposal.id" class="d-table-cell align-middle px-2 py-0 h-100" :class="{'print-hidden': proposal.hide || proposal?.contact && this.printOptions.hideProposalsFromPrint}">
                <div style="border-right: 1px solid #195D5C !important; border-left: 1px solid #195D5C !important;" class="h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold">
                  <div class="align-self-center">
                    {{ this.proposalTotal(proposal.id).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} €
                  </div>
                </div>
              </td>
            </tr>
            <!--end:total-->

            <!--begin:total-->
            <tr class="h-100 lot-header">
              <td class="text-end" colspan="3">TVA</td>
              <td v-for="proposal in this.proposals" :key="proposal.id" class="d-table-cell align-text-bottom px-2 py-0 h-100" :class="{'print-hidden': proposal.hide || proposal?.contact && this.printOptions.hideProposalsFromPrint}">
                <div style="border-right: 1px solid #195D5C !important; border-left: 1px solid #195D5C !important;" class="cell h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold">
                  <div class="align-self-center">
                    {{ this.proposalVatTotal(proposal.id).replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} €
                  </div>
                </div>
              </td>
            </tr>
            <!--end:total-->

            <!--begin:total-->
            <tr class="h-100 lot-header">
              <td class="text-end" colspan="3">Total TTC</td>
              <td v-for="proposal in this.proposals" :key="proposal.id" class="d-table-cell align-middle px-2 py-0 h-100" :class="{'print-hidden': proposal.hide || proposal?.contact && this.printOptions.hideProposalsFromPrint}">
                <div style="border-right: 1px solid #195D5C !important; border-left: 1px solid #195D5C !important;" class="h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold">
                  <div class="align-self-center">
                    {{ (parseFloat(this.proposalTotal(proposal.id))+parseFloat(this.proposalVatTotal(proposal.id))).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} €
                  </div>
                </div>
              </td>
            </tr>
            <!--end:total-->

            <!--begin:roundedBottom-->
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td v-for="proposal in this.proposals" :key="proposal.id" class="px-2 py-0 h-100" :class="{'print-hidden': proposal.hide || proposal?.contact && this.printOptions.hideProposalsFromPrint}">
                <div class="rounded-bottom h-100 w-100 border border-primary border-top-0 text-center p-2"
                    :class="proposal ? 'bg-primary text-white' : 'bg-primary text-white'"
                >

                </div>
              </td>
            </tr>
            <!--end:roundedBottom-->
            </tbody>
            <!--end::body-->
          </table>
          <!--end::Table-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Row-->
  </div>
  <!--end::Content-->
</template>

<script>
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent";
import kalmStore from "@/store";
import PrintHeader from "@/views/project/tender/PrintHeader";
import { ALL_CONTACTS_QUERY } from "@/views/contacts/data/contacts_graphql";
import {
    CURRENT_USER_QUERY,
    GET_PROPOSALS_FOR_PROJECT,
    MUTATION_TENDER_HIDE_PROPOSAL
} from "@/graphql/graphql";

export default {
  name: "TenderPage",
  components: { ProjectBreadcrumbComponent, PrintHeader},
  apollo: {
    contacts: {
      query: ALL_CONTACTS_QUERY,
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
    allProposals: {
      query: GET_PROPOSALS_FOR_PROJECT,
      variables() {
        return {
          projectId: kalmStore.getters.currentProjectFocus,
        }
      },
        update: (data) => {
            return data.proposals
        }
    },
  },
  computed: {
      proposals() {
          return this.allProposals.filter(p => {
              let ret = p.modeLots === "ALL"
              if(p.modeLots === "PARTIAL") {
                  p.lots.forEach(l => {
                      if(l.id === this.selectedLot) {
                          ret = true;
                      }
                  })
              }
              return ret;
          }) || [];
      },
    isModalActive: function() {
      return kalmStore.state.display.profileToggled;
    },
    selectedLotObject() {
      return this.currentProject.lots.find(l => l.id === this.selectedLot)
    },
    lots(){
        const allLots = this.currentProject.lots || [];
        const lots = allLots.filter(l => l.number.startsWith(this.selectedLotObject?.number))
        lots.forEach(lot => {
            this.showLots[lot.id] = true;
        })
        return lots;
    },
    currentProjectFocus() {
      return kalmStore.getters.currentProjectFocus;
    },
    currentProject() {
      return kalmStore.getters.getProjectById(this.currentProjectFocus);
    },
    card_count_proposals_created(){
      return this.proposals.length
    },
    card_count_proposals_sent(){
      let ret = 0;
      this.proposals.forEach(p => {
        ret += JSON.parse(p.steps).find(s => s.id === "1")?.status ? 1 : 0;
      })
      return ret
    },
    card_count_proposals_received(){
      let ret = 0;
      this.proposals.forEach(p => {
        ret += JSON.parse(p.steps).find(s => s.id === "2")?.status ? 1 : 0;
      })
      return ret
    },
  },
  methods: {
      getChiffragesForLot(lot) {
         return this.proposals[0]?.chiffrages.filter(c => c.lot.id === lot.id).sort(function(a, b) {
             if (a.order < b.order) { return -1; }
             if (a.order > b.order) { return 1; }
             return 0;
         })
      },
    hideProposal(proposalId, hide) {
      this.$apollo.mutate({
        mutation: MUTATION_TENDER_HIDE_PROPOSAL,
        variables: {
          proposalId,
          hide
        },
        refetchQueries: [
          {
            query: GET_PROPOSALS_FOR_PROJECT,
            variables: {
              projectId: kalmStore.getters.currentProjectFocus,
            }
          }
        ]
      })
    },
    printTender() {
      this.printOptions.title = "Consultation des entreprises";
      this.printOptions.subtitle = "Synthèse comparative";
      this.printOptions.text = "Pour le lot n°"+this.selectedLotObject?.number+" — "+this.selectedLotObject?.name
      console.log(this.selectedLotObject)
      this.printOptions.hideProposalsFromPrint = false;

      this.$nextTick(() => this.printDocument());
    },
    printEstimation() {
      this.printOptions.title = "Étude estimative des prix";
      this.printOptions.subtitle = "";
      this.printOptions.text = "Pour le lot n°"+this.selectedLotObject?.number+" — "+this.selectedLotObject?.name+". Ce document est une estimation non contractuelle, il est présenté à titre informatif uniquement."
      this.printOptions.hideProposalsFromPrint = true;

      this.$nextTick(() => this.printDocument());
    },
    printDocument() {
      window.print();
    },
    getProposalById(proposalId){
      return this.proposals.find(p => p.id === proposalId);
    },
    proposalSubtotal(proposalId, lotId) {
      const proposal = this.getProposalById(proposalId);

      //if(proposal.lots.map(l => l.id).includes(lotId)){
      let lotSubTotal = 0;

      if (!this.showLots[lotId]) {
        lotSubTotal = 0;
      }
      else if(JSON.parse(proposal.lotGlobalValues)[lotId]?.sum) {
        lotSubTotal = JSON.parse(JSON.parse(proposal.lotGlobalValues)[lotId].sum)
      }
      else {
        // Proposal includes lot, sum its chiffrages
        if(proposal.lots.map(l => l.id).includes(lotId)) {
          lotSubTotal = proposal.chiffrages.filter(c => c.lot.id === lotId)
            .map(c => {
              const ret = parseFloat(c?.value)
              return isNaN(ret) ? 0 : parseFloat(c?.value)
            })
            .reduce((a, b) => a + b, 0)
        }

        // Sum all sous-lots
        const masterLot = this.lots.find(l => l.id === lotId);
        if(masterLot && this.lots.filter(l => l.number.startsWith(masterLot.number+'.') && l.number !== masterLot.number && l.id !== lotId && l.number.split(".").length - 1 === masterLot.number.split(".").length).length) {
          const subLots = this.lots.filter(l => l.number.startsWith(masterLot.number+'.') && l.number !== masterLot.number && l.id !== lotId && l.number.split(".").length - 1 === masterLot.number.split(".").length);
          for(const x in subLots) {
            const l = subLots[x];
            lotSubTotal += this.proposalSubtotal(proposalId, l.id);
          }
        }
      }
      return lotSubTotal
      /*}
      else {
        // Lot is not part of proposal, we inspect its sub lots
        return 0
      }*/
    },
    proposalSubtotalFormatted(proposalId, lotId) {
      const proposal = this.getProposalById(proposalId);

      if(!this.showLots[lotId]) {
        return "-"
      }
      else if(proposal.lots.map(l => l.id).includes(lotId)){
        let lotSubTotal = this.proposalSubtotal(proposalId, lotId)

        return lotSubTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € HT";
      }
      else {
        return proposal?.contact ? "NC" : "NC"
      }
    },
    proposalTotal(proposalId) {
      let ret = 0;
      this.lots.filter(l => l.number.split(".").length - 1 === 0).forEach(l => {
        ret += this.proposalSubtotal(proposalId, l.id)
      })
      return ret.toFixed(2);
    },
    proposalVatSubtotal(proposalId, lotId) {
      const proposal = this.getProposalById(proposalId);

      let lotSubTotal = 0;

      if (!this.showLots[lotId]) {
        lotSubTotal = 0;
      }
      else if(JSON.parse(proposal.lotGlobalValues)[lotId]?.sum) {
        // defaults to proposal vat if lot vat is not defined
        const lotVatRate = (JSON.parse(proposal.lotGlobalValues)[lotId].vat === null
          || JSON.parse(proposal.lotGlobalValues)[lotId].vat === ""
          || isNaN(JSON.parse(proposal.lotGlobalValues)[lotId].vat)
        ) ? proposal.vatDefaultValue : JSON.parse(proposal.lotGlobalValues)[lotId].vat;
        lotSubTotal = JSON.parse(proposal.lotGlobalValues)[lotId].sum * lotVatRate / 100;
      }
      else {
        // includes vat sum of chiffrages if lot is included
        if(proposal.lots.map(l => l.id).includes(lotId)) {
          lotSubTotal = proposal.chiffrages.filter(c => c.lot.id === lotId)
            .map(c => {
              const ret = parseFloat(c?.vatValue)
              return isNaN(ret) ? 0 : parseFloat(c?.vatValue)
            })
            .reduce((a, b) => a + b, 0)
        }

        const masterLot = this.lots.find(l => l.id === lotId);

        if(masterLot && this.lots.filter(l => l.number.startsWith(masterLot.number+'.') && l.number !== masterLot.number && l.id !== lotId && l.number.split(".").length - 1 === masterLot.number.split(".").length).length) {
          const subLots = this.lots.filter(l => l.number.startsWith(masterLot.number+'.') && l.number !== masterLot.number && l.id !== lotId && l.number.split(".").length - 1 === masterLot.number.split(".").length);
          for(const x in subLots) {
            const l = subLots[x];
            lotSubTotal += this.proposalVatSubtotal(proposalId, l.id);
          }
        }
      }
      return lotSubTotal
    },
    proposalVatTotal(proposalId) {
      let ret = 0;
      this.lots.filter(l => l.number.split(".").length - 1 === 0).forEach(l => {
        ret += this.proposalVatSubtotal(proposalId, l.id)
      })
      return ret.toFixed(2);
    },
    toggleLot(lotId) {
      if(this.toggledLots.includes(lotId)) {
        this.toggledLots = [...this.toggledLots.filter(l => l !== lotId)]
      }
      else {
        this.toggledLots.push(lotId)
      }
    },
    toggleHideLot(lotId) {
      this.showLots[lotId] = !this.showLots[lotId];
    },
    lotToggled(lotId) {
      return this.toggledLots.includes(lotId)
    },
    showOuvrageInfoTooltip(ouvrageId, status){
      this.tooltip = status ? ouvrageId : '';
    },
    getProposalChiffrageForOuvrage(proposal, lot, ouvrageId) {
      const chiffrageValue = proposal.chiffrages.find(c => c.ouvrageId === ouvrageId)?.value
      if(proposal.lots.map(l => l.id).includes(lot)) {
        return parseFloat(chiffrageValue || "0").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € HT"
      }
      else {
        return "-"
      }
    }
  },
  data() {
    return {
      lines: [],
      contacts: [],
      showLots: {},
      selectedLot: "",
      allProposals: [],
      modals: {},
      printOptions: {
        title: "Consultation des entreprises",
        subtitle: "Synthèse comparative",
        hideProposalsFromPrint: false,
        text: "",
      },
      tenderImportFromExcel: {
        error: false,
        chiffrages: [],
        countQuantity: 0,
        countUnit: 0,
        countUnitPrice: 0,
        countValue: 0,
      },
      tooltip: '',
      toggledLots: []
    }
  },
}
</script>

<style scoped>
.edit-proposal-button {
  cursor: pointer;
}
.edit-proposal-button:hover, .edit-proposal-button:hover i {
  cursor: pointer;
  background-color: white !important;
  color: gray !important;
}

@media print {
  .print-hidden {
    display: none !important;
  }

  .print-noindent {
    margin-left: 0 !important;
  }

  tr, th {
    page-break-after:auto;
    page-break-before:auto;
    page-break-inside: avoid;
  }
}

@media screen {
  .web-sticky {
    position: sticky;
    top: 60px;
  }
}
</style>